.jumbotron {
    ul, ol {
        li {
            color: rgba(255, 255, 255, 0.8);
            font-weight: 300;
        }
    }
}

.center {
    text-align: center;
}

p, li {
    font-size: 18px;
}

.section-basic {
    p {
        b {
            font-weight: 600;
        }
    }

    .drink1, .drink2 {
        min-height: 1541px;
    }

    .drink3, .drink4 {
        min-height: 1833px;
    }
}