/* 
81, 95, 120
220, 181, 169
140, 194, 180
207, 139, 120
*/

.Countdown {
  display: flex;
  justify-content: space-around;
  font-family: sans-serif;
  text-align: center;
}

.time {
  font-size: 32px;
  color: #fff;
}

.dimension {
    color: #fff;
}

.center {
  text-align: center;
}

.tick {
  font-size:1rem; white-space:nowrap; font-family:arial,sans-serif;
}

.tick-flip,.tick-text-inline {
  font-size:2.2em;
}

.tick-label {
  margin-top:1em;font-size:1em;
}

.tick-char {
  width:1.5em;
}

.tick-text-inline {
  display:inline-block;text-align:center;min-width:1em;
}

.tick-text-inline+.tick-text-inline {
  margin-left:-.325em;
}

.tick-group {
  margin:0 .5em;text-align:center;
}

.tick-text-inline {
   color: #595d63 !important; 
}

.tick-label {
   color: #595d63 !important; 
}

.tick-flip-panel {
   color: #fff !important; 
}


.tick-flip-panel-text-wrapper {
   line-height: 1.45 !important; 
}

.tick-flip-panel {
   background-color: rgb(81, 95, 120) !important; 
}

.tick-flip {
   border-radius:0.12em !important; 
}