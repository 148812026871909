// style for the landing page
.index-page .squares{
  @include linear-gradient($primary-states, $primary);

  &.square4, &.square5 {
    @include linear-gradient($default-states, $default);
  }

  &.square3, &.square6 {
    @include linear-gradient($secondary-states, $secondary);
  }
}
.register-page, .section.section-signup{
  .squares, .square {
    @include linear-gradient($primary-states, $primary);
  }
}
