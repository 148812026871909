div {
    .page-header {
        background-image: url('../../assets/img/B90A0233.jpg'); 
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-color: rgba(100,100,100,0.3);
        background-blend-mode: lighten;
        background-position: center center;
    }
}

.center {
    text-align: center;
}